@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600;800&display=swap');



.Sidebar {
  min-height: 100vh;
  width: 250px;
  background-color: #012F6B;

}

.SidebarList {
  height: auto;
  padding: 0;
  width: 100%;
  margin: 0px;
  padding-top: 55px;
}

.SidebarList .category {
  width: 100%;
  height: 60px;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  color: white;
  align-items: center;
  font-family: 'Nunito', sans-serif;
  padding-top: 30px;
  padding-left: 10%;
  font-weight: bold;
  text-decoration: none;
  pointer-events: none;
}


.SidebarList .row {
  width: 100%;
  height: 60px;
  list-style-type: none;
  margin: 0%;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito', sans-serif;
  text-decoration: none;

}

.SidebarList .row:hover {
  cursor: pointer;
  background-color: #315585;
}

.SidebarList .active {
  background-color: #3685FE;
}

.SidebarList .active:hover {
  background-color: #3685FE;
}


.row #icon {
  flex: 35%;
  display: grid;
  place-items: center;
}

.row #title {
  flex: 65%;
}
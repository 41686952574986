/********* APP CONTAINERS *********/

body {
  background-color: #EEE;
}

.App {
  min-height: 100vh;
  width: 100vw;
}

.App .nav-banner-wrap {
  display: flex;
}

.banner-content-wrap {
  display: flex;
  flex-direction: column;
  width: 100vw;
  // height: 100vh;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
}

/********* TYPEOGRAPHY *********/

a {
  color: #61dafb;
}

label {
  font-size: 18px;
}

* {
  font-family: 'Nunito', sans-serif;
}

p {
  margin: 0px;
}

h1 {
  margin-top: 0px;
}

h2 {
  margin-top: 0px;
  margin-bottom: 5px;
}

table {
  text-align: left;
  border-collapse: collapse;
}

tr {
  min-height: 50px;
}

td {
  text-align: left;
  background-color: white;
  color: black;
  border-collapse: separate;
  border-top: 1px solid #E5E5E5;
}

th {
  // border-bottom: 1px solid black;
  background-color: #929FB4;
  color: white;
}

/********* CONTAINERS *********/

.page-wrap {
  font-family: 'Nunito', sans-serif;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.line {
  margin-top:30px;
  width: 800px;
  border-bottom: 1px solid black;
  margin-bottom: 30px;
}

.flex-row {
  display: flex;
  align-items: center;

  &.end {
    justify-content: flex-end;
  }
}

.scrollable-table-container {
  overflow: scroll;
}

.scrollable-table-container th{
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
}

.--clickable {
  cursor: pointer;
}
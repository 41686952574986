.WinConditions {
  padding: 20px;

  .WinConditions__list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  .Button.NewWinConditionButton {
    background-color: white;
    width: 200px;
    height: 202px;
    color: #012F6B;

    &:hover {
      background-color: #F8F8F8;
    }

    .Text {
      color: #012F6B;
    }
  }
}
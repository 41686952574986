.MessageDatabase {
  table {
    width: 1000px;
  }

  .first-col {
    padding-left:15px;
  }
  
  .last-col {
    text-align: center;
  }
}

.search-filter-row {
  width: 1000px;
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  align-items: center;
}

.search-filter-row button {
  margin-left: auto;
  align-items: center;
  background-color: #3685FE;
  font-weight: bold;
  border: none;
  color: white;
  padding: 10px 15px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

.WinConditionPattern {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  .WinCondition__PatternRow {
    display: flex;
    flex-direction: row;
    gap: 10px;
    
    .WinCondition__Cell {
      border: 1px solid #DDD;
      width: 18px;
      height: 18px;
      background-color: #DDD;

      &.Cell--relative {
        background-color: transparent;

        &.Cell--active {
          background-color: #012F6B;
        }
      }

      &.Cell--relative:not(.--clickable) {
        border-color: transparent;
      }

      &.Cell--active {
        border: 1px solid #012F6B;
        background-color: #012F6B;
      }
    }
  }
}
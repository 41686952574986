@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600;800&display=swap');





.page-wrap {
    font-family: 'Nunito', sans-serif;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

table {
    width: 1000px;
    // height: 200px;
    text-align: left;
    border-collapse: collapse;
  }
  
  .first-col{
    padding-left:15px;
  }
  
  tr {
    height:50px;
  }

  th {
    // border-bottom: 1px solid black;
    background-color: #929FB4;
    color: white;
  }
    
  td {
    text-align: left;
    background-color: white;
    color: black;
    border-collapse: separate;
    border-top: 1px solid #E5E5E5;
    // border-top: #E5E5E5;
  }

    .last-col {
      text-align: center;
    }

    .line {
      margin-top:30px;
      width: 800px;
      border-bottom: 1px solid black;
      margin-bottom: 30px;
    }


    .search-filter-row {
        width: 1000px;
        display: flex;
        flex-direction: row;
        // padding-top: 20px;
        padding-bottom: 20px;
        align-items: center;
    }

    .search-filter-row button {
        margin-left: auto;
        align-items: center;
        background-color: #3685FE;
        font-weight: bold;
        border: none;
        color: white;
        padding: 10px 15px;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
    }

  
.WinCondition {
  background-color: white;
  padding: 10px;
  width: 180px;
  position: relative;

  .WinCondition__Edit {
    position: absolute;
    right: 5px;
    top: 12px;
  }

  .WinCondition__Identifier {
    text-align: center;
    max-width: 150px;
    margin: 0px auto;
  }
}
.App-header {
  height: 75px;
  display: flex;
  align-items: stretch;
  color: white;
  box-shadow: 0 0px 32px 0 rgb(0 0 0 / 13%), 0 0px 7px 0 rgb(0 0 0 / 19%);
  background-color: white;

  .App-navbutton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    color: white;
    text-decoration: none;

    .App-logo {
      height: 60px;
      pointer-events: none;
    }

    .Banner__name {
      padding-top: 10px;
      padding-bottom: 10px;
      color: #333;
      margin-bottom: 0px;
    }
  }

  .App-options {

    .UserOptions {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: stretch;
      margin-right: 70px;
      color: gray;

      .UserOptions__Button {
        min-width: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
  }
}

.Card {
  background-color: white;
  padding: 15px;
  width: 250px;

  h2 {
    text-align: center;
  }

  $gap: 5px;

  .Card__Columns {
    display: flex;
    flex-direction: row;
    gap: $gap;

    .Card__Column {
      margin: 0px 3px;
      display: flex;
      flex-direction: column;
      gap: $gap;

      .Card__Cell {
        display: flex;
        align-items: center;
        justify-content: center;
        $size: 40px;
        border-radius: 5px;
        background-color: #012F6B;
        color: white;
        width: $size;
        height: $size;


        &.--hit {
          background-color: #FF540A;
        }
      }
    }
  }
}
.DateField {
  width: 100%;
  position: relative;
  background-color: rgba(255,255,255,0.3);

  .DateField__input {
    font-size: 18px;
    padding: 5px 10px;
    width: -webkit-fill-available;
    position: relative;
    border-width: 0 0 2px;
    border-color: #929FB4;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: #282828;
    outline: none;
  }
  
}
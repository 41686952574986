.CompanySettings {

  .H1 {
    text-align: center;
    margin-bottom: 10px;
  }

  .CompanySettings__item {
    display: flex;
    align-items: middle;
    margin-bottom: 10px;

    & > h3 {
      margin-right: 10px;
    }

    & > h3, & > div {
      flex: 1;
    }
  }
}
.AdminPanel {
  width: 1200px;

  .AdminPanelLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    .AdminPanelLink {
      margin: 5px;
      width: 130px;
      height: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 5px;

      &:hover {
        background-color: #EEE;
      }
    }
  }
}
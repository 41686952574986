.Confirm {
  width: 632px;
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;

  .Confirm__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1.Text {
      width: 450px;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 30px;
      border-bottom: 1px solid #8F919F;
    }
  }

  &.small {
    width: 352px;
    .Confirm__content {
      h1.Text {
        width: 352px;
        border-bottom: none;
        padding-bottom: 0px;
      }
    }
  }
  .Confirm__close {
    position: absolute;
    top: 20px;
    right: 20px;
    :hover {
      cursor: pointer;
    }
  }

  .Confirm__children {
    margin-bottom: 10px;
  }

  .Confirm__footer {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 30px;
  }
}
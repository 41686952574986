.Container {
  width: fit-content;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;

  &.middle {
    margin-left: auto;
    margin-right: auto;
  }

  &.content-center {
    text-align: center;
  }
}
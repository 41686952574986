.NewSiteModal {
  min-width: 500px;

  .field, .submit-row {
    margin-top: 10px;
  }

  .submit-row {
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
  }


}
.NewWinConditionModal {
  min-width: 400px;
  display: flex;
  flex-direction: column;

  .Text.H1 {
    margin-bottom: 20px;
  }

  .WinConditionPattern {
    margin: 20px 0px;
  }

  .Button {
    align-self: flex-end;
  }
}
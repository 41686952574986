@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600;800&display=swap');

.CurrentGame {
  .search-filter-new-row {
    justify-content: space-between;
  }
  
  .message-table {
    min-height: 500px;

    .Calendar {
      height: 500px;
    }
  }
}

.BingoRoundsViewSelector {
  display: flex;
  align-items: center;

  .ViewOption {
    background-color: #E5E5E5;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #929FB4;
    padding: 2px 4px;
    color: #929FB4;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-right: 0;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .ViewOption--active {
    background-color: white;
    color: #012F6B;
  }
}

.game-info-controls {
  width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: center;

  .flex-row {
    gap: 10px;
  }

  .game-id {
    font-weight: bold;
  }

  .start-date {
    margin-right: 20px;
  }
}

table {
  width: 1000px;
  text-align: left;
  border-collapse: collapse;
}
  
.first-col {
  padding-left: 15px;
}

tr {
  height: 50px;
}

th {
  background-color: #929FB4;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
}
  
td {
  text-align: left;
  background-color: white;
  color: black;
  border-collapse: separate;
  border-top: 1px solid #E5E5E5;
  padding: 5px;
}

.last-col {
  text-align: center;
}

.line {
  margin-top:30px;
  width: 800px;
  border-bottom: 1px solid black;
  margin-bottom: 30px;
}

.message-style {
  // padding-left:15px;
  padding-right:15px;
  max-width: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-filter-new-row {
  width: 1000px;
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  align-items: center;
}

.search-filter-new-row .add-button {
  background-color: #3685FE;
  font-weight: bold;
  border: none;
  color: white;
  padding: 10px 15px;
  text-decoration: none;
  cursor: pointer;
}

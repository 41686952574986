.passwordField {
  width: 100%;
  position: relative;
  background-color: rgba(255,255,255,0.3);
  transition: 0.3s all;
  
  .passwordField__input {
    padding: 5px 10px;
    width: -webkit-fill-available;
    position: relative;
    border-width: 0 0 2px;
    border-color: #929FB4;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    color: #282828;
    outline: none;
  }
  
  &.passwordField--disabled {
    cursor: not-allowed;
    .passwordField__input {
      pointer-events: none;
    }
  }
  
  &.passwordField--error {
    .passwordField__input {
      border: 1px solid red;
    }
    .passwordField__message {
      color: red;
    }
  }
}
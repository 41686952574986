.Button {
  background-color: #3685FE;
  font-weight: bold;
  color: white;
  padding: 10px 15px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;

  &.text {
    background-color: transparent;
    color: inherit;
    font-weight: 400;
  }

  &.hazard {
    align-items: center;
    background-color: #800000;
    font-weight: bold;
    border: none;
    color: white;
    padding: 10px 15px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }
}
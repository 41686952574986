.bg-setter {
  background-color: #012F6B;
}

.Container.middle {
  margin-top: 20vh;
  margin-bottom: 100vh;
  padding: 35px;
  padding-left: 40px;
  padding-right: 40px;
}

.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 500px;

  .Login-title {
    text-align: center;
  }

  .Button {
    margin: 5x auto;
    margin-top: 10px;
  }

  .TextField, .passwordField {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .TextField label, .passwordField label {
    font-style: italic;
  }
  .TextField__input, .passwordField__input {
    margin-top: 5px;
    font-size: 15px;
  }
}
.Dropdown {
  position: relative;

  .DropdownToggle {
    cursor: pointer;
  }

  .DropdownMenu {
    max-height: 300px;
    position: absolute;
    z-index: 10;
    display: none;

    &.DropdownMenu--open {
      display: block;
    }

    .DropdownItem {
      padding: 7px;
      background-color: white;
      color: black;
      border-bottom: 1px solid #CCC;
    
      &:hover {
        background-color: #EEE;
      }
    
      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    
      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}
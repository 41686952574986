.BindoRoundsTable {
  height: 625px;
  overflow: scroll;

  tr:hover {
    .EditButton svg {
      display: block;
    }
  }

  .EditButton {
    width: 24px;
    padding: 0px;
    
    svg {
      display: none;
    }
  }
}
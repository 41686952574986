.Select {
  position: relative;

  label {
    font-size: 18px;
  }

  .Selected, .Placeholder {
    margin-left: 5px;
  }

  .Placeholder {
    color: #999;
  }

  .Selector {
    border-bottom: 2px solid #929FB4;
    padding: 5px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 22px;
  }

  .Select__Menu {
    position: absolute;
    box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.3);
    width: 100%;
    border-radius: 5px;
    z-index: 1;
    background-color: white;

    .Option {
      cursor: pointer;
      padding: 2px 5px;
      border-bottom: 1px solid #929FB4;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: #EEE;
      }
    }
  }
}
.modal__overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .modal__title {
    text-align: center;
  }
}

.ReactModal__Content.modal__content {
  position: fixed;
  top: 10%;
  
  padding: 15px;
  background-color: white;
  box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.7);
}

.modal__close {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  font-size: 24px;
}

.checkbox__disabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  svg {
    fill: #d3d3d3;
  }

  &:hover {
    svg {
      cursor: not-allowed;
      background-color: transparent;
    }
  }

  label {
    font-weight: normal;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: 3px;
  }
}

.checkbox-field-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:not(.--checked) {
    &:hover {
      svg {
        background-color: #A9C7F5;
      }
    }
  }

  label {
    font-weight: normal;
    padding-top: 0px;
    margin-bottom: 0px;
    margin-left: 3px;
  }
}

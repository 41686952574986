.Text {
  margin: 0;
  color: #2C2C2C;


  &.H1 {
    font-size: 28px;
    line-height: 21px;
  }

  &.H2 {
    font-size: 16px;
    line-height: 25px;
  }

  &.H3 {
    font-size: 14px;
    letter-spacing: -0.28px;
  }

  &.P {
    font-size: 12px;
    color: #4D4747;
    letter-spacing: -0.2px;
  }

  &.P, &.H3 {
    line-height: 16px;
  }

  &.H1, &.H2 {
    letter-spacing: 0.15px;
  }

  &.H1, &.H3 {
    font-weight: 300;
  }

  &.P, &.H2 {
    font-weight: 400;
  }

}
.Table {
  tbody {
    max-height: 600px;
    overflow: auto;
    display: block;
  }

  thead, tbody tr {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  td {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600;800&display=swap');

.page-wrap {
  font-family: 'Nunito', sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.message-table {
  margin-bottom: 30px;
}

.back-done-row {
  width: 1000px;
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
}

.back-done-row .back-button {
  font-weight: bold;
  color: gray;
  text-decoration: none;
}

.back-done-row .done-button {
  margin-left: auto;
  align-items: center;
  background-color: #3685FE;
  font-weight: bold;
  border: none;
  color: white;
  padding: 10px 15px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

table {
  width: 1000px;
  text-align: left;
  border-collapse: collapse;
}
  
.first-col {
  padding-left:15px;
}

.last-col {
  text-align: center;
}

.line {
  margin-top:30px;
  width: 800px;
  border-bottom: 1px solid black;
  margin-bottom: 30px;
}

.message-style {
  // padding-left:15px;
  padding-right:15px;
  max-width: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-filter-new-row {
  width: 1000px;
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  align-items: center;
}

.search-filter-new-row button {
  margin-left: auto;
  align-items: center;
  background-color: #3685FE;
  font-weight: bold;
  border: none;
  color: white;
  padding: 10px 15px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

  
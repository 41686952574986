.switch {
  border-radius: 15px;
  position: relative;
  background-color: #ddd;
  height: 26px;
  width: 46px;
  border: 3px solid white;
  padding: 2px;
  box-sizing: border-box;
  transition: background-color 500ms ease;
  -webkit-box-shadow: 2px 2px 3px 1px #B5B5B5;
  box-shadow: 2px 2px 3px 1px #B5B5B5;
  cursor: pointer;

  .switch__toggle {
    border-radius: 50%;
    transition: left 500ms ease, 
      background-color 500ms ease;
    position: absolute;
    background-color: white;
    height: 16px;
    width: 16px;
    left: 2px;
  }

  &.switch--on {
    background-color: #FF8D30;

    .switch__toggle {
      left: 22px;
    }
  }

  &.switch--disabled {
    background-color: #ccc;
    border: 3px solid #eee;
    cursor: not-allowed;
    
    .switch__toggle {
      background-color: #eee;
    }
  }
}

.SwitchContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
/* The Modal (background) */
.NewContentForm {
  display: block;
  width: 500px;
  min-height: 300px;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);

  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 30px;
    border: 1px solid #888;
    width: 42%;
  }

  h1 {
    margin: 0px;
    padding-bottom: 30px;
    color: #012F6B;
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover, .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .field {
    padding-bottom: 20px;
  }

  label {
    font-size: medium;
    color: #929FB4;
  }

  .form-input {
    outline: 0;
    border-width: 0 0 2px;
    border-color: #929FB4;
    font-size: larger;
    font-family: inherit;
  }
  
  .form-input:focus {
    border-color: #012F6B;
  }

  .submit-row {
    align-items: center;
    flex-direction: row-reverse;
    display: flex;
  }

}

.NewUserForm {
  min-width: 500px;

  .submit-row {
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: flex-end;

    .Button {
      margin-top: 10px;
    }
  }

  .checkbox-field {
    margin-bottom: 5px;
  }

  .form-error {
    color: red;
  }

}
